.admin-left-side{
  background-color: #2c2e3e; 
  padding:0;
  height: 100%;
}
.admin-right-side{
    background-color:#f9f9fb;
}
.admin-list-group{
   padding:15px 0 15px 0;
}

.menu-item:hover{
    background-color:#1f212d;
}
.menu-item:hover>.list-group-item{
    color:#fff;
}
.menu-item >.list-group-item{
    font-size: 14px;
    font-family:Roboto,sans-serif;
    text-decoration: none;
    color: #a2a5bd;
}
.admin-list-group>a{
    text-decoration: none;
}
.car-list{
    background-color: #fff;
    padding:15px;
}
.car-list-table{
    padding-left:15px;
    color: #000;
    border: 1px solid #dee2e6;
    width: 100%;
}
.car-list-header{
    background-color: #2c2e3e;
    color: #fff;
   
}
.car-list-header>th{
    padding-top: 5px;
    padding-bottom: 5px;
    color: #fff; 
    font-size: 14px;
}
.car-list-table>tbody{
    font-size: 14px;
}
.odd-row{
    background-color: #e9ecef;}
    .row-content{
 background-color: #fff;
 padding:15px;
 margin-top: 10px;
 margin-bottom: 25px;
    }
.content-title{
        border-bottom: 1px solid #e9ecef;
        margin-bottom: 10px;
    }
    .dropzone-admin{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        border-width: 2px;
        border-radius: 2px;
        border-color: #eeeeee;
        border-style: dashed;
        background-color: #fafafa;
        color: #bdbdbd;
        outline: none;
        transition: border .24s ease-in-out;
    }
    
    .feature-label{
        font-size: 14px;
    }
@font-face {
    font-family: "alqalam ishtiaq";
    src: url('fonts/alqalam-ishtiaq.otf');
    font-weight: normal;
font-style: normal;
}
@font-face {
    font-family: "Al Rashed Riyadh";
    src: url('fonts/Al-Rashed-Riyadh.TTF');
    font-weight: normal;
font-style: normal;
}
@font-face {
    font-family: "Helvetica Neue Light";
    src: url('fonts/HelveticaNeue-Light.otf');
    font-weight: normal;
font-style: normal;
}
@font-face {
    font-family: "Helvetica Neue Medium";
    src: url('fonts/helvetica-neue-medium.ttf');
    font-weight: normal;
font-style: normal;
}
@font-face {
    font-family: "Helvetica Neue Thin";
    src: url('fonts/helvetica-neue-ce-35-thin.ttf');
  

}